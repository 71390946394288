


























































import { Component, Vue } from 'vue-property-decorator'
import { ShopModule } from '@/store/modules'


@Component({
  components: {
    ShopItemCard: () => import('@/components/Shop/ShopItemCard.vue'),
    ShopItemModal: () => import('@/components/Shop/ShopItemModal.vue'),
    ShopFilter: () => import('@/components/Shop/ShopFilter.vue')
  }
})
export default class ShopHandler extends Vue {
  @ShopModule.Action getShopItems
  @ShopModule.Getter filteredShopItems

  currentPage = 1
  perPage = 18

  get rows() {
    return this.filteredShopItems.length
  }

  get paginationMin() {
    return (this.currentPage - 1) * this.perPage
  }

  get paginationMax() {
    if (this.paginationMin + this.displayedShopItems.length === this.rows) {
      return this.filteredShopItems.length
    }

    if (this.perPage > this.displayedShopItems.length) {
      return this.displayedShopItems.length
    }

    return this.currentPage * this.perPage > this.displayedShopItems
      ? this.displayedShopItems.length
      : this.currentPage * this.perPage
  }

  get displayedShopItems() {
    return this.filteredShopItems.slice(this.paginationMin, this.currentPage * this.perPage)
  }

  async refreshShop() {
    await this.getShopItems()
  }
}
